const menu__button = document.querySelector('.lto__mobile__menu__button');
const menu__button__animation = document.querySelector('.lto__mobile__menu');
const mobile__menu = document.querySelector('.lto__nav--mobile');

const openMenu = function(e) {
  menu__button__animation.classList.add('opened');
  mobile__menu.classList.add('opened');
}

const closeMenu = function(e) {
  menu__button__animation.classList.remove('opened');
  mobile__menu.classList.remove('opened');
}

const onClick = function(e) {
  console.dir(menu__button__animation);

  if (menu__button__animation.classList.contains('opened')) {
    return closeMenu();
  }
  return openMenu();
}

menu__button.addEventListener('click', onClick);
